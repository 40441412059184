<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col class="col-12">
                    <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('academic_year')">
                            <b-form-input
                                v-model="form.academic_year"
                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col class="col-12">
                    <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('period')">
                            <semester-type-selectbox v-model="form.semester"
                                                     :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col class="col-12">
                    <ValidationProvider name="rank" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('rank')">
                            <b-form-input type="number" step="1" v-model="form.rank"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Component
    import SemesterTypeSelectbox from "@/components/interactive-fields/SemesterTypeSelectbox"
    import {ValidationProvider, ValidationObserver} from "vee-validate"

    // Services
    import SemesterService from "@/services/SemesterService";
    import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";

    export default {
        components: {
            AcademicYearsSelectbox,
            SemesterTypeSelectbox,
            ValidationProvider, ValidationObserver
        },
        data() {
            return {
                formLoading: false,
                form: {
                    semester: null,
                    academic_year: null,
                    rank: null
                }
            }
        },
        methods: {
            async createForm() {
                let isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    SemesterService.store(this.form)
                                   .then((response) => {
                                       this.$toast.success(this.$t("api." + response.data.message));
                                       this.$emit("createFormSuccess")
                                   })
                                   .catch(error => {
                                       this.showErrors(error, this.$refs.formModalValidate)
                                   })
                                   .finally(() => {
                                       this.formLoading = false
                                   })
                }
            }
        }
    }
</script>
